@import './../../../styles/variables.scss';

@font-face {
  font-family: 'anticon';
  src: url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot');
  src:
    /* IE10-11 */ url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot?#iefix') format('embedded-opentype'),
    /* IE9 */ url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff') format('woff'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2 */ url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf') format('truetype'),
    /* iOS 4.1- */ url('https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont') format('svg');
}

// Note: Some !important overrides are necessary because apps like WSS have their own !important.
// Additional overrides might be required as we integrate with more apps:

@mixin appAndPanelStyles($styleSuffix) {
  font-family: 'Raleway', Helvetica, Arial, serif #{$styleSuffix};
  font-size: 16px #{$styleSuffix};

  button.ms-Panel-closeButton:hover {
    background-color: white #{$styleSuffix};
    padding-bottom: 0 #{$styleSuffix};
  }

  a,
  .AppName {
    font-family: 'Raleway', Helvetica, Arial, serif #{$styleSuffix};
  }

  a:hover {
    text-decoration: none #{$styleSuffix};
  }

  button.ms-Button {
    &:hover {
      border-style: solid #{$styleSuffix};
      font-family: 'Raleway', Helvetica, Arial, serif #{$styleSuffix};
      font-weight: normal #{$styleSuffix};
    }

    &.ms-Button--primary:hover {
      background-color: map-get($fabricColors, themeDarkAlt) #{$styleSuffix};
      color: map-get($fabricColors, white) #{$styleSuffix};
    }

    &.ms-Button--default:hover {
      background-color: map-get($fabricColors, neutralLight) #{$styleSuffix};
      color: map-get($fabricColors, neutralPrimary) #{$styleSuffix};
    }

    &.ms-Button--action:hover {
      background-color: transparent #{$styleSuffix};
      color: map-get($fabricColors, themePrimary) #{$styleSuffix};
    }

    &.ms-Panel-closeButton:hover {
      background-color: transparent #{$styleSuffix};
      color: map-get($fabricColors, neutralPrimary) #{$styleSuffix};
      border-style: none #{$styleSuffix};
    }
  }
}

.WidgetApp {
  @include appAndPanelStyles(null)
}

.WidgetAppPanel {
 @include appAndPanelStyles('!important')
}

.WidgetApp {
  button {
    line-height: 0.9em;
    padding-top: 0px;
    padding: 8px;
    font-size: 0.9em;
    font-weight: 300;
    font-family: 'Raleway', Helvetica, Arial, serif;

    .WidgetIcon {
      color: map-get($fabricColors, themeSecondary);
      font-family: 'anticon' !important;
      margin: 0 0;

      .ms-Spinner {
        margin-right: 0;
      }
    }

    &:hover {
      padding-bottom: 8px !important;
    }
  }

  i {
    font-size: 1em;
    padding-top: 1px;
  }

  .WidgetName {
    margin-left: 0.33em !important;
    margin-right: 0 !important;
  }

  @media (max-width: $ms-screen-max-md) {
    button {
      min-width: 1em;
    }

    .WidgetName {
      display: none;
    }
  }
}

.WidgetAppPanel {
  .PrimaryTitle {
    font-size: 1.25em;
    font-weight: 800;
    color: map-get($bceColors, title);
    margin-bottom: 0.9em;
  }

  .LoadingPanel {
    .Spinner {
      position: absolute;
    }
  }
}
