@import '../../../styles/variables.scss';

.WidgetAppPanel {
  .AppNavItem {
    padding: 0.5em 0;

    .ms-Image {
      padding: 0 0;
    }

    a {
      font-weight: 500;
      color: map-get($fabricColors, black);
    }

    a:hover {
      color: map-get($bceColors, linkHover);
    }
  }

  button.EditMenu,
  button.EditMenu:hover {
    background-color: white !important;
    padding-bottom: 0 !important;

    i {
      color: map-get($fabricColors, themeDarkAlt);
    }
  }
}
