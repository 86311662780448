@import './../../../styles/variables.scss';

.AppList {
  background-color: map-get($fabricColors, white);

  .ModeSelector {
    background-color: map-get($fabricColors, neutralLight);
    padding-left: 0;

    .ms-SearchBox {
      height: 40px;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 10rem;
    }

    @media (max-width: $ms-screen-max-sm) {
      .ms-SearchBox {
        width: 5rem;
      }
    }
  }

  footer {
    padding-top: 0.5rem;
    border-top: 1px solid map-get($bceColors, title);
    margin: 0 2rem 2rem 2rem;

    h1 {
      color: map-get($bceColors, title);
      margin: 0.2rem 0 0.7rem 0;
      font-size: 1.15rem;
    }

    div {
      line-height: 1.5rem;
    }
  }
}

.ToggleAppToast {
  background: map-get($bceColors, toastBackground);
  color: map-get($fabricColors, white);

  button {
    color: map-get($fabricColors, white);
    margin-left: 0.5rem;
  }
}

.ToggleAppToastProgressBar {
  background: map-get($fabricColors, white);
  height: 2px;
}
