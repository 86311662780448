@import './../../../styles/variables.scss';

.TopBar {
  > div {
    height: 48px;
    background-color: map-get($bceColors, themeDark);
    padding: 0rem 1rem 0rem 0rem;
    display: flex;

    &.Small,
    &.Medium {
      display: none;
    }

    @media (max-width: $ms-screen-max-md) {
      &.Medium {
        display: flex;
      }

      &.Small,
      &.Full {
        display: none;
      }
    }

    @media (max-width: $ms-screen-max-sm) {
      &.Small {
        display: flex;
      }

      &.Full,
      &.Medium {
        display: none;
      }
    }

    header {
      margin-left: 1rem;
      font-size: 1.05rem;
      font-weight: normal;
      float: left;
      margin-top: 0.9rem;
      flex: 1;
      color: white;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: white;
        text-decoration: unset;

        &:hover {
          color: white;
        }
      }
    }

    .MoreButton,
    .SearchButton {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      min-width: 1rem;
      border: none;
      background-color: map-get($bceColors, theme);
      color: white;

      &:hover {
        background-color: map-get($bceColors, themeAlt) !important;
        color: white !important;
      }

      span {
        color: white !important;
      }
    }

    .CloseButton {
      flex: 0;
      margin-right: auto;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      min-width: 1rem;
      border: none;
      padding: 0 1rem;
      color: white;
      span {
        color: white !important;
      }
    }

    .ms-SearchBox {
      margin-top: 0.5rem;
      border: none;
      width: 11rem;
      color: white;
      background-color: map-get($bceColors, theme);

      .ms-SearchBox-iconContainer,
      input,
      button {
        color: white;

        &::placeholder {
          color: white;
        }
      }

      @media (max-width: $ms-screen-max-sm) {
        width: 9rem;
      }
    }

    .WidgetApp {
      margin-left: 0.5rem;
      margin-top: 0.5rem;

      button.ms-Button.ms-Button--default {
        background-color: map-get($bceColors, theme);
        color: white;

        &:hover {
          background-color: map-get($bceColors, themeAlt) !important;
          color: white !important;
        }
      }

      span {
        color: white !important;
      }
    }

    .ms-Persona {
      margin-top: 0.6rem;
      margin-left: 1rem;

      .ms-Persona-details {
        cursor: default;

        .ms-Persona-primaryText {
          color: white;
        }
      }
    }

    @media (max-width: $ms-screen-max-lg) {
      .ms-Persona {
        .ms-Persona-details {
          display: none;
        }
      }
    }

    @media (max-width: $ms-screen-max-md) {
      .ms-Persona {
        margin-left: 0.5rem;
      }
    }
  }

  .SearchContainer {
    height: 40px;
    display: none;
    .ms-SearchBox {
      width: 100%;
      margin-left: 1rem;
      margin-top: 0;
    }

    @media (max-width: $ms-screen-max-md) {
      display: flex;
    }
  }
}
