@import './../../../styles/variables.scss';

.AppContainer {
  background-color: map-get($bceColors, themeLight);

  > header {
    font-size: 1.75rem;
    margin: 2rem 2rem 1.45rem 2rem;
    color: map-get($fabricColors, white);
    font-weight: 800;
  }
}

.User {
  background-color: map-get($fabricColors, white);
}

.Admin {
  background-color: map-get($fabricColors, white);
  padding: 2rem 2rem;
}

.ms-Button a {
  text-decoration: none;
  color: map-get($fabricColors, neutralPrimary);
}
