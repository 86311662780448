@import '../../../styles/variables.scss';

.AppItem {
  padding: 0.5rem 0 0.33rem 0;
  break-inside: avoid-column;
  display: flex;

  .ms-Image {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: map-get($fabricColors, black);
    flex: 1;
    font-weight: 500;
  }

  a:hover {
    color: map-get($bceColors, linkHover);
  }

  .ms-Button {
    color: map-get($fabricColors, neutralPrimary);
    transition: background-color 0.5s ease;
  }

  .ms-Button:hover {
    background-color: grayscale(map-get($fabricColors, themeLight));
  }
}

.AppDescriptionContainer {
  font-size: 0.875rem;
  margin-bottom: 1.33rem;
  margin-right: 1rem;
  color: map-get($fabricColors, neutralSecondary);

  .AppDescription {
    margin-right: 1rem;
    margin-left: 2.5rem;
  }

  .MoreInformationContainer {
    margin-top: 0.5rem;
    text-align: right;
  }
}
