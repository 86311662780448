@import './../../../styles/variables.scss';

.AppList {
  .ByCategory {
    padding: 1rem 2rem;
    display: block;
    overflow-x: hidden;

    .ApplicationGroup {
      margin-bottom: 1rem;

      h1 {
        font-size: 1.25rem;
        font-weight: 800;
        color: map-get($bceColors, title);
        padding-bottom: 0.5rem;
        border-bottom: 1px solid map-get($bceColors, title);
        margin-bottom: 0.5rem;

        &.Collapsed {
          border-bottom: none;
          padding-bottom: 0;
        }

        .ms-Button {
          padding: 0 0;
          width: auto;
          height: 1.5rem;
          line-height: 1.5rem;
          vertical-align: middle;
          color: map-get($fabricColors, neutralPrimaryAlt);
          margin-right: 0.5rem;
          transition: 0.3s all;

          .ms-Button-icon {
            margin: 0 0;
            color: map-get($bceColors, title);
          }

          &.Collapsed {
            transform: rotate(90deg);
          }
        }
      }
    }

    .Column {
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &:first-child {
        padding-left: 0;
        padding-right: 1rem;
      }

      &:last-child {
        padding-left: 1rem;
        padding-right: 0;
      }
    }

    .OneColumnList {
      display: none;

      .Column {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .ThreeColumnList {
      .Column {
        width: 33.33333%;
      }
    }
  }
}

@media (max-width: $ms-screen-max-lg) {
  .AppList .ByCategory {
    .OneColumnList {
      display: block;
    }

    .ThreeColumnList {
      display: none;
    }
  }
}
