@import '../../../styles/variables.scss';

button.HelpButton {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  min-width: 1rem;
  border: none;
  padding: 0 0.6rem;
  background-color: map-get($bceColors, theme);
  color: map-get($fabricColors, white);

  &:hover {
    background-color: map-get($bceColors, themeAlt) !important;
    color: map-get($fabricColors, white) !important;
  }

  span {
    color: map-get($fabricColors, white) !important;
  }
}

.ms-Callout.HelpCallout {
  .ms-Callout-main {
    padding: 1rem 1rem;
  }
  header {
    font-size: 1rem;
    color: map-get($fabricColors, neutralPrimary);
  }
  hr {
    height: 1px;
    border: none;
    color: map-get($fabricColors, neutralLight);
    background-color: map-get($fabricColors, neutralLight);
    margin: 0.8rem -0.5rem;
  }
  .ms-Link {
    font-size: 0.8rem;
    display: block;
    margin-top: 0.3rem;
    .LinkText {
      color: map-get($fabricColors, neutralPrimaryAlt);
      margin-left: 0.5rem;
    }
  }
  .CloseHelpButton.ms-Button {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 1rem;
    height: 1rem;

    i {
      font-size: 0.6rem;
    }
  }
}
