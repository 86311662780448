body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
span,
button,
a {
  font-family: 'Raleway', Helvetica, Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Form {
  .FormRow {
    margin: 0 0 0.5rem 0;

    .ms-Checkbox {
      margin: 0 0 0.5rem 0;
    }
  }

  .FormButton {
    margin: 0 0.5rem 0 0;
  }

  .FormFooter {
    margin: 1.5rem 0 0 0;
  }

  .FormContent {
    margin: 1rem 0;
  }
}

.ms-Button .ms-Spinner {
  margin-right: 0.5rem;
}

.ms-TeachingBubble-content .ms-TeachingBubble-primaryButton .ms-Button-label {
  color: #8857a4;
}
