@import './../../../styles/variables.scss';

.AZList {
  padding: 1rem 2rem;

  .Column {
    width: 33.33333333%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:first-child {
      padding-left: 0;
      padding-right: 1rem;
    }

    &:last-child {
      padding-left: 1rem;
      padding-right: 0;
    }
  }
}

@media (max-width: $ms-screen-max-lg) {
  .AZList .Column {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
