@import '../../../styles/variables.scss';

.WidgetAppPanel {
  .AppEditItem {
    padding: 0.3em 0;
    margin: 0;
    margin-bottom: 0.3em;
    cursor: move;
    background-color: map-get($fabricColors, neutralLighterAlt);
    border: 1px solid map-get($fabricColors, neutralLight);
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    .DragIcon {
      cursor: move;
      float: left;
      margin-top: 0.5em;
      margin-left: 0.5em;
      margin-right: 0.5em;
      color: map-get($fabricColors, neutralSecondary);
    }

    .IconAndTitle {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: map-get($fabricColors, black);
      font-weight: 500;

      .ms-Image {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5em;
        padding: 0;
      }
    }

    .RemoveButton {
      float: right;
      margin-top: 0.5em;
      margin-left: 0.5em;
      margin-right: 0.5em;
      cursor: pointer;
      color: map-get($fabricColors, neutralSecondary);
    }
  }

  .AppLimitReached {
    font-size: 0.875em;
    border: 1px solid map-get($fabricColors, neutralSecondary);
    padding: 1.5em 1em;
  }

  .AppSearchBox {
    height: 39px;
    font-size: 0.875em;
  }

  .ActionBar {
    margin-top: 1em;

    button:hover {
      padding-bottom: 0px !important;
    }

    .Cancel,
    .ms-Button.Cancel.ms-Button--default:hover {
      margin-left: 0.5em;
    }
  }

  .ResetLink {
    margin-top: 1.5em;
    margin-bottom: 2em;
    font-size: 0.875em;
    background: none !important;
    font-weight: normal;
  }
}

.ResetDialog {
  * {
    font-family: 'Raleway', Helvetica, Arial, serif !important;
  }

  button {
    border: none;
  }

  button:hover {
    padding-bottom: 0 !important;
  }

  button.ms-Button--default:hover {
    background-color: map-get($fabricColors, neutralLight) !important;
  }

  button.ms-Button--primary:hover {
    background-color: map-get($fabricColors, themeDarkAlt) !important;
    color: white !important;
  }

  .ms-Dialog-title {
    font-weight: 300 !important;
  }
}
