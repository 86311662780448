@import '../../../styles/variables.scss';

.WidgetAppPanel {
  .SearchResultsBox {
    margin-top: 5px;
    border: 1px solid map-get($fabricColors, neutralLight);
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    font-size: 0.875em;

    .SearchResultsTitle {
      color: map-get($fabricColors, neutralSecondary);
      border-bottom: 1px solid map-get($fabricColors, neutralLight);
      padding: 0.8em 0.7em;
    }

    .SearchResults {
      padding: 0.5em 0;

      button {
        padding: 0 0.7em;
      }

      .SearchResultItem {
        margin: 0.1em 0;
        padding: 0.3em 0.7em;
      }

      .SearchResultItem:hover {
        background-color: map-get($fabricColors, neutralLight);
        cursor: pointer;
      }
    }

    .NoResults {
      background-color: map-get($fabricColors, neutralLighter);
      text-align: center;
    }
  }
}
